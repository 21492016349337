.tooltipContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.tooltipTextContainer {
  text-align: start;
}

.tooltipList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notMobile {
  font-size: 3.125rem !important;
}

@media only screen and (max-width: 599px) {
  .notMobile {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .mobile {
    display: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
